// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./qPVUdl8b8-0.js";

const cycleOrder = ["vEmF1L0dG", "VFpm2wE0j"];

const variantClassNames = {vEmF1L0dG: "framer-v-2lua26", VFpm2wE0j: "framer-v-o5m4fm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "vEmF1L0dG", "Variant 2": "VFpm2wE0j"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "vEmF1L0dG"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vEmF1L0dG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterdprvvd = activeVariantCallback(async (...args) => {
setVariant("VFpm2wE0j")
})

const onMouseLeave19v6vjz = activeVariantCallback(async (...args) => {
setVariant("vEmF1L0dG")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AUIj7", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({VFpm2wE0j: {href: {webPageId: "NU0vuFcN7"}, openInNewTab: false}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-2lua26", className)} framer-1w68x5e`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"vEmF1L0dG"} onMouseEnter={onMouseEnterdprvvd} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}} variants={{VFpm2wE0j: {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({VFpm2wE0j: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave19v6vjz}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Pozrite si moje práce</motion.p></React.Fragment>} className={"framer-1iz7au0"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"Maw3_BPj3"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} variants={{VFpm2wE0j: {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({VFpm2wE0j: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Pozrite si moje práce</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AUIj7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AUIj7 .framer-1w68x5e { display: block; }", ".framer-AUIj7 .framer-2lua26 { height: 50px; overflow: hidden; position: relative; width: 198px; will-change: var(--framer-will-change-override, transform); }", ".framer-AUIj7 .framer-1iz7au0 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-AUIj7.framer-v-o5m4fm .framer-2lua26 { text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 198
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VFpm2wE0j":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerqPVUdl8b8: React.ComponentType<Props> = withCSS(Component, css, "framer-AUIj7") as typeof Component;
export default FramerqPVUdl8b8;

FramerqPVUdl8b8.displayName = "fdsfsf";

FramerqPVUdl8b8.defaultProps = {height: 50, width: 198};

addPropertyControls(FramerqPVUdl8b8, {variant: {options: ["vEmF1L0dG", "VFpm2wE0j"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerqPVUdl8b8, [])